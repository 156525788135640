.peoplePage-root {
  .people-header {
    @apply flex justify-end mb-8;
    .people-per-page-selector {
      @apply inline-flex items-center;
      label {
        @apply flex-grow mr-2 pb-0 text-gray-400 font-normal;
      }
      .ant-select {
        @apply w-16;
      }
    }
  }
  .people-list {
    @apply grid gap-4 list-none p-0 mb-8;
    grid-template-columns: repeat(auto-fill, minmax(300px, auto));
  }
  .person-box {
    &-cover {
      @apply h-32 object-cover;
    }
    .ant-card-body {
      @apply -mt-14;
    }
    .ant-card-meta {
      @apply flex-col mb-2;
      .ant-avatar {
        @apply border-2 border-solid border-white;
      }
      .avatar-icon {
        @apply -right-6 -bottom-3;
        svg {
          @apply text-4xl;
        }
      }
      &-detail {
        @apply mt-2;
      }
      &-title {
        @apply mb-1 text-blue-400 font-semibold text-base font-sans;
      }
      &-description {
        @apply text-gray-400;
      }
    }
  }
}
