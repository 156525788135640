.label-root {
  @apply inline-block pb-2 font-semibold text-sm;

  &-disabled {
    @apply opacity-50;
  }

  .label-required {
    @apply text-rose-500 text-sm;
  }
}
