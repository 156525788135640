.layout-root-sider {
  width: 312px !important;
  min-width: 312px !important;
  max-width: 312px !important;
  flex: 0 0 312px !important;
  background: rgb(17, 39, 54) !important;

  @apply h-full max-h-full overflow-y-auto;

  &-collapsed {
    width: 80px !important;
    min-width: 80px !important;
    max-width: 80px !important;
    flex: 0 0 80px !important;

    .layout-root-sider-group-title {
      display: none;
    }
  }

  &-logo {
    @apply p-6 top-0 z-10 sticky bg-gray-900;

    img {
      height: 31px;
      @apply w-auto;
    }
  }

  &-group-title {
    @apply text-xs text-gray-500 uppercase ml-3;
  }

  .sidebar-icon {
    font-size: 1.125rem !important;
    transition: color 0.3s;

    @apply bg-transparent text-white text-opacity-40;
  }

  &-link {
    @apply flex items-center;

    span {
      @apply text-white text-opacity-60;
    }

    &:hover {
      span {
        @apply text-white;
      }
    }

    &.active {
      .sidebar-icon {
        @apply text-teal-300;
      }

      span {
        @apply text-white;
      }

      &:before {
        content: '';
        width: 5px;
        background-color: rgba(117, 221, 221, 1) !important;
        @apply block rounded-r-lg;
      }
    }
  }

  .ant-menu {
    &-dark,
    &-sub,
    &-submenu-popup {
      background: rgb(17, 39, 54) !important;
    }

    &-sub {
      .ant-menu-item {
        padding-left: 54px !important;
        margin-inline: 4px;
        margin-block: 4px;
      }
    }

    &-item,
    &-title-content,
    &-submenu-title {
      @apply font-semibold text-white text-opacity-60;
    }

    &-item {
      padding-left: 0;
      border-radius: 0;

      &-icon {
        @apply font-semibold text-white text-opacity-40;
      }

      &:hover {
        background-color: rgb(91, 117, 135) !important;
        border-radius: 10px;

        .layout-root-sider-link.active {
          @apply cursor-default;

          .sidebar-icon {
            @apply text-white;
          }

          &:before {
            border-radius: 0;
            background-color: #fff !important;
          }
        }
      }

      &-selected {
        @apply bg-transparent;
        span {
          @apply text-white;
        }
      }
    }
    &-submenu {
      &:hover {
        .sidebar-icon {
          color: #fff !important;
        }
      }
    }
  }

  [class^='ant-menu'],
  [class*=' ant-menu'] {
    @apply text-base leading-10;
  }
}
.ant-menu-inline-collapsed-tooltip {
  display: none;
}
