.send-tokens-modal-receiver {
  @apply flex justify-between mb-6;
  &-name {
    @apply flex-1 mr-3;
  }
  &-amount {
    @apply w-1/4;
  }
}

.modal-subheading.send-tokens-modal-subheading {
  @apply text-gray-400;
}
