.headerMenu-root {
  @apply w-full max-w-screen-xl;

  &-app {
    min-width: 150px;
    @apply flex h-10 items-center;
  }

  .headerMenu {
    @apply flex justify-end;

    &-user {
      display: flex;
    }

    &-avatar.avatar-root {
      border: 3px solid rgb(17 39 54) !important;
      @apply flex rounded-full;

      .ant-avatar-image {
        @apply border-0;
      }
    }

    .ant-menu-item {
      @apply flex items-center;
    }

    .userArrow {
      font-size: 20px !important;
      color: rgb(158, 160, 164) !important;
    }

    .ant-menu-submenu {
      @apply flex items-center;

      &:first-child {
        padding-right: 5px !important;
      }

      &-title {
        line-height: 1;
      }
    }

    .app-icon {
      svg {
        width: 20px;
        height: 20px;
      }
    }

    .ant-badge {
      .ant-badge-count {
        top: 6px;
        font-size: 0.625rem;
        line-height: 20px;
        background: rgb(244, 54, 89);
        transform: translate(66%, -50%);
        @apply font-semibold;
      }
      .app-icon {
        svg {
          width: 24px !important;
          height: 24px !important;
        }
      }
    }
    .ant-menu-title-content {
      margin-inline-start: 0;
    }
  }
  .ant-menu-horizontal,
  .ant-menu-horizontal > .ant-menu-item::after,
  .ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after,
  .ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected::after,
  .ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after,
  .ant-menu-light.ant-menu-horizontal > .ant-menu-submenu:hover::after,
  .ant-menu-light.ant-menu-horizontal > .ant-menu-item-active::after,
  .ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-active::after,
  .ant-menu-light.ant-menu-horizontal > .ant-menu-item-open::after,
  .ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-open::after {
    @apply border-b-transparent;
  }
}

.ant-menu-light .ant-menu-item:not(.ant-menu-item-selected):hover {
  background: #fff !important;
}
.ant-menu-submenu-popup .ant-menu-vertical > .ant-menu-item {
  height: max-content !important;
  pointer-events: none;

  a,
  button,
  .ant-tabs-tab-btn {
    pointer-events: visible;
  }
}
.ant-menu-light .ant-menu-item-selected {
  background-color: #fff !important;
}
