.user {
  @apply flex items-start font-sans;

  &-management {
    th:last-child {
      color: transparent !important;
    }
    .ant-table-cell:last-child {
      @apply text-right;
    }
    tr:last-child {
      .ant-table-cell {
        @apply border-b-0;
      }
    }
  }

  &-avatar {
    border: 2px solid #fff;
    @apply rounded-full;
  }

  &-email,
  &-joined,
  &-permissions {
    @apply text-gray-400;
  }

  &-info {
    @apply flex flex-col justify-start ml-3;

    &-name {
      @apply text-gray-900 font-semibold;
    }
    &-job-role {
      @apply text-gray-400;
    }
  }

  &-edit {
    &-link {
      @apply flex justify-center items-center w-8 h-8;

      &-wrapper {
        @apply flex justify-end;
      }
    }
    svg {
      @apply fill-gray-400 w-4 h-4;
    }

    &:hover {
      svg {
        @apply fill-sky-400;
      }
    }
  }

  &-team {
    @apply uppercase text-xs font-semibold px-4 py-1 rounded-full;
    &.team-00 {
      @apply bg-slate-50 text-slate-500;
    }
    &.team-01 {
      @apply bg-orange-50 text-orange-500;
    }
    &.team-02 {
      @apply bg-blue-50 text-sky-400;
    }
    &.team-03 {
      @apply bg-fuchsia-50 text-fuchsia-500;
    }
    &.team-04 {
      @apply bg-indigo-50 text-indigo-500;
    }
    &.team-05 {
      @apply bg-pink-50 text-pink-500;
    }
    &.team-06 {
      @apply bg-yellow-50 text-yellow-500;
    }
    &.team-07 {
      @apply bg-teal-50 text-teal-500;
    }
    &.team-08 {
      @apply bg-amber-50 text-amber-500;
    }
  }
}

.allTeamsView-root {
  .allTeamsView-item {
    @apply flex cursor-pointer;
    &-data {
      @apply flex flex-col;
      &-approval {
        @apply mb-6;
      }
    }
    &-name {
      @apply font-semibold;
    }
    &-details {
      @apply text-gray-400;
    }
  }
  &_approval-wrapper {
    & + & {
      @apply mt-12;
    }
  }
}

.singleTeamView-table {
  @apply max-w-full;
  .singleTeamView-data {
    @apply text-gray-400;
  }
  &-last-column {
    @apply flex justify-end mr-4;
  }
  th:last-child {
    text-indent: -9999px;
  }
}
.approvalRequestsNumber {
  border: 1px solid blue;
}
