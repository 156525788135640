.formCustom-root {
    .ant-input-affix-wrapper,
    .ant-picker.input-date {
        @apply py-3 px-4 h-auto;

        > input.ant-input,
        .ant-picker-input {
            @apply text-sm leading-normal;
        }
    }
}