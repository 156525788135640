.TagsSelect-root {
  .tagsSelect {
    @apply block w-full mb-3;
  }

  .tagsSelect-button {
    @apply absolute top-1/2 right-0 z-10 h-min mr-3;
    padding-top: 2px;
  }

  .tagsSelect-level-selector {
    @apply absolute right-10 z-20 text-gray-900;
    top: 30px;
    .ant-select-single.ant-select-lg {
      &:not(.ant-select-customize-input) {
        .ant-select-selector {
          @apply items-center;
          &,
          &.ant-select-open {
            @apply h-full border-none;
          }
        }
      }
      &.ant-select-show-arrow .ant-select-selection-item {
        padding-inline-end: 28px;
      }
    }

    &-disabled {
      .ant-select .ant-select-selection-placeholder {
        @apply text-gray-400;
      }
      .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        @apply bg-white;
      }
      .ant-select-arrow {
        @apply cursor-not-allowed;
      }
    }

    .ant-select-selection-placeholder {
      @apply mr-2;
    }

    .ant-select-arrow {
      padding-top: 2px;
    }
  }
}

.tagsSelect-select-dropdown {
  width: 144px !important;
  .ant-select-item {
    @apply py-3;
  }
}
