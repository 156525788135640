.integration-card-root {
  .icon-and-title {
    @apply flex flex-row pb-4 items-center;
    .integration-title {
      @apply text-gray-900 text-base font-semibold;
    }
  }
  .app-icon svg {
    @apply w-10 h-10;
  }
  .description {
    @apply border-b border-neutral-200 flex flex-row text-gray-400;
  }
  .integration-card-controls {
    @apply flex justify-end;
    &-button {
      @apply h-max font-semibold p-0;
    }
    .cancel-integration {
      @apply ml-4 text-gray-900;
    }
  }
}
