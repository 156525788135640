.teamTimeTracker-header {
  @apply flex justify-between items-center mt-4;
  .teamTimeTracker-select {
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
      @apply pl-0;
      .ant-select-selection-item {
        @apply text-lg font-semibold leading-9;
      }
    }
  }
  &-actions {
    @apply flex;
    .teamTimeTracker-primary-button {
      @apply ml-2;
    }
  }
}

.teamTimeTracker-calendar-options {
  @apply flex justify-between items-center;
  .teamTimeTracker-search {
    @apply w-2/5;
    .input {
      @apply h-11;
    }
  }
}

.teamTimeTracker-calendar {
  @apply mt-8;
  &-grid {
    @apply grid text-gray-400;
    & > div {
      @apply text-center border border-solid border-gray-200;
    }
  }
  &-team-member,
  &-date {
    @apply py-4 font-semibold;
  }
  & &-team-member {
    @apply pl-4 text-start;
  }
  & &-team-member-info {
    @apply p-6 text-start;
    &-header {
      @apply flex items-center mb-3;
      .avatar-root {
        @apply pr-3;
      }
    }
  }
  &-team-member-details {
    @apply flex flex-col;
    &-name {
      @apply font-semibold text-gray-900;
    }
  }
  &-time {
    &-total {
      @apply py-2 bg-gray-200 border-b border-solid border-gray-200;
    }
    &-entries {
      @apply p-3;
      min-height: 10rem;
    }
    &-entry {
      @apply flex flex-col mb-3 px-4 py-3 rounded-lg font-sans text-white text-xs text-left;
      &-billable {
        @apply bg-gray-600;
      }
      &-nonbillable {
        @apply bg-red-300;
      }
      &-project {
        @apply mb-1 font-semibold truncate;
      }
      &-more {
        @apply inline-block px-2.5 py-2 rounded-full bg-gray-200 text-gray-900 font-semibold;
      }
    }
  }
  &-status-tag {
    @apply mt-4 mb-6;
  }
  &-link {
    @apply font-semibold;
  }
}
