body,
html,
#root,
.App {
  @apply w-full h-full flex;
}
body {
  @apply min-h-full font-normal text-gray-900 font-sans;
}

::-webkit-scrollbar {
  display: none;
}

main {
  flex: 1;
}

strong {
  font-weight: 600;
}

.button-default {
  outline: 2px solid transparent;
  transition: outline 300ms ease-in-out;
  @apply flex items-center py-5 justify-center;

  &:hover {
    color: rgb(17, 39, 54) !important;
    border-color: rgb(208, 213, 221) !important;
    @apply outline outline-2 outline-gray-100;
  }

  &:focus {
    outline-color: rgba(208, 213, 221, 0.5);
    @apply outline outline-2;
  }

  &:disabled,
  &[disabled] {
    color: rgb(208, 213, 221) !important;
    @apply bg-white;

    svg {
      filter: grayscale(1);
    }
  }
}

.link-as-button {
  @apply rounded-lg font-semibold py-2.5 px-3.5;
  &-primary,
  &-primary.ant-typography {
    @apply bg-sky-400 text-white font-semibold outline outline-offset-2 outline-2;
    transition: background-color 0.3s;
    &:hover,
    &:active,
    &:focus {
      @apply bg-blue-400 text-white;
    }
    &:focus,
    &:active {
      @apply outline-gray-200;
    }
  }

  &:disabled {
    @apply opacity-30;
  }
}

.upload-crop-modal {
  .ant-modal-content {
    @apply px-0;
    .ant-modal-header {
      @apply px-6 pb-3;
    }
    .ant-modal-footer {
      @apply px-6 pt-4;
    }
    .ant-modal-body {
      @apply bg-black;
    }
    .img-crop-control {
      @apply pb-4 text-white;
      .ant-slider {
        &-rail {
          @apply bg-slate-200;
        }
        &-track {
          @apply bg-sky-400;
        }
      }
    }
    .ant-btn-primary {
      @apply bg-sky-400 font-semibold py-3 h-auto text-sm font-sans text-white;
    }
    .ant-btn-default {
      @apply hidden;
    }
  }
}

.form-actions {
  @apply flex justify-end;
  button:first-child {
    @apply mr-3;
  }
}

.inner-subheading,
h3.inner-subheading {
  @apply uppercase text-sm font-semibold text-gray-600;
}

input:-webkit-autofill {
  -webkit-background-clip: text;
}

.item-with-avatar-and-data {
  //TODO: utilise these styles in components that use them
  @apply flex;
  .avatar-root {
    @apply mr-3;
  }
  .item-data {
    @apply flex flex-col font-sans;
    &-main {
      @apply font-semibold;
    }
    &-sub {
      @apply text-gray-400;
    }
  }
}
