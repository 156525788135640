.input-root {
  .input {
    @apply border-gray-300 text-sm font-sans rounded-lg;

    input::placeholder {
      @apply text-gray-400 text-sm;
    }
    &:disabled {
      @apply opacity-30;

      &::placeholder {
        @apply text-sm;
      }
    }

    &.ant-input-affix-wrapper {
      .ant-input-prefix {
        .anticon {
          @apply mr-0;
        }
      }
      &-focused {
        box-shadow: 0 0 4px 0 #2c73a4, 0 1px 10px 0 rgba(57, 139, 195, 0.05);
      }
      &-disabled {
        @apply bg-transparent text-gray-900;

        .ant-input-disabled {
          @apply text-gray-400;
        }
      }
    }

    &-number,
    &-date {
      @apply block;
    }

    &-number {
      @apply flex items-center h-12 w-full;
      &.ant-input-number-affix-wrapper:focus,
      &.ant-input-number-affix-wrapper-focused {
        box-shadow: 0 0 4px 0 #2c73a4, 0 1px 10px 0 rgba(57, 139, 195, 0.05);
      }
      .ant-input-number {
        @apply h-auto;
        .ant-input-number-handler-wrap {
          @apply opacity-100;
          .ant-input-number-handler {
            @apply border-none;
            &:hover {
              @apply h-2/5;
            }
          }
        }
      }
      .ant-input-number:hover .ant-input-number-handler {
        border-inline-start: none;
      }
    }

    .ant-input-suffix > *:not(:last-child) {
      margin-inline-end: 0.25rem;
    }
  }
  .error-message {
    @apply mt-3 mb-0 text-rose-500 text-xs;
  }
}

.input-tel {
  input.form-control {
    height: 46px;
    padding-left: 25%;
    @apply w-full border-gray-300 text-sm rounded-lg;

    &:hover {
      @apply border-blue-400;
    }
    &:focus {
      box-shadow: 0 0 4px 0 #2c73a4, 0 1px 10px 0 rgba(57, 139, 195, 0.05);
    }
    &::placeholder {
      @apply text-sm;
    }
  }
  .flag-dropdown,
  .flag-dropdown.open {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    @apply flex justify-center w-1/5 bg-gray-200;

    &:hover,
    &:focus,
    &:active {
      @apply bg-white;
    }
    &:hover {
      @apply border-blue-400;
    }
    .selected-flag:focus {
      @apply bg-inherit;
    }
  }
  .flag-dropdown.open {
    box-shadow: 0 0 4px 0 #2c73a4, 0 1px 10px 0 rgba(57, 139, 195, 0.05);
    @apply bg-white;

    .country-list {
      box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
        0 9px 28px 8px rgb(0 0 0 / 5%);
      @apply top-8 left-0 rounded-xl border-none;

      .search {
        @apply w-full pr-3;

        .search-box {
          @apply ml-0 w-full h-10 border border-solid border-gray-300 rounded-xl p-3;

          &:hover {
            @apply border-blue-400;
          }
          &:focus {
            box-shadow: 0 0 4px 0 #2c73a4, 0 1px 10px 0 rgba(57, 139, 195, 0.05);
          }
        }
      }
    }
  }
}

.ant-picker {
  &.ant-picker-disabled {
    @apply bg-transparent;

    input,
    input:disabled,
    input::placeholder {
      @apply text-gray-400 text-sm;
    }
  }

  &:hover {
    @apply border-blue-400;
  }

  &.ant-picker-focused {
    box-shadow: 0 0 4px 0 #2c73a4, 0 1px 10px 0 rgba(57, 139, 195, 0.05);
  }

  &.input-date {
    @apply h-10 flex;
  }

  &-large .ant-picker-input > input {
    @apply text-sm;
  }
}

.input-date-popup {
  box-shadow: 1px 1px 4px #dee2e6;
  @apply rounded-lg border border-solid border-slate-200;

  .ant-picker-header {
    @apply border-none pt-4;

    &-super-prev-btn,
    &-super-next-btn {
      @apply hidden;
    }
    &-view {
      padding-left: 18px;
      @apply order-1 text-left;
    }
    &-prev-btn {
      @apply order-2;
    }
    &-next-btn {
      @apply order-3;
    }
  }
  .ant-picker-content {
    th,
    td {
      @apply text-xs text-zinc-500;
      &.ant-picker-cell-in-view {
        @apply text-gray-900;
      }
      &.ant-picker-cell-today,
      &.ant-picker-cell-selected {
        .ant-picker-cell-inner {
          @apply rounded-full;
          &::before {
            @apply border-none;
          }
        }
      }
      &.ant-picker-cell-today {
        .ant-picker-cell-inner {
          @apply bg-gray-200;
        }
      }
      &.ant-picker-cell-selected {
        .ant-picker-cell-inner {
          @apply bg-sky-400 text-white;
        }
      }
    }
  }
  .ant-picker-footer {
    @apply hidden;
  }
}
