.contactModal-root {
  .contactModal-item {
    @apply flex mb-6;
    &-icon {
      svg {
        @apply text-xl;
      }
    }
    &-info {
      @apply ml-5;
    }
    &-data {
      @apply text-sm font-semibold mb-1;
    }
    &-label {
      @apply text-gray-400 text-xs mb-0;
    }
  }
  .contactModal-button {
    @apply text-sm;
    svg {
      @apply text-2xl;
    }
  }
}
