.professionalSkills-suggestions {
  @apply uppercase text-sm font-semibold text-gray-600;
}

.professionalSkills-actions {
  @apply flex justify-end;
  .professionalSkills-button:first-child {
    @apply mr-3;
  }
}

.experienceSection-cards-grid {
  @apply grid grid-cols-2 gap-4 mb-4;
}

.educationSection-card {
  @apply mb-4;
  .ant-card-body {
    @apply flex;
    .educationSection-card-info {
      @apply pl-2;
    }
  }
}

.educationExperience-delete-icon {
  @apply text-4xl;
  svg {
    fill: white;
  }
}

.experienceSection-card {
  .experienceSection-card-header {
    @apply pl-2;
  }
}

.educationSection-card,
.experienceSection-card {
  .ant-card-body {
    @apply relative py-5 px-4;
    .educationExperience-card-title {
      @apply mb-0 text-base;
    }
    .educationExperience-card-subtitle,
    .educationExperience-card-date {
      @apply text-sm text-gray-400;
    }
    .educationExperience-card-subtitle {
      @apply mb-2 font-normal;
    }
    .educationExperience-card-date {
      @apply mb-3;
    }
    .educationExperience-card-description {
      @apply mb-0 text-sm;
      .experienceCard-description-text {
        &,
        & p {
          @apply mb-0;
        }
        &.truncated-element {
          @apply relative overflow-hidden text-ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      &-button {
        @apply p-0;
      }
    }
    .educationExperience-card-actions {
      @apply hidden absolute top-4 right-4;
      .educationExperience-card-button {
        @apply m-0 p-0 h-min;
      }
      .educationExperience-card-icon {
        @apply text-lg;
      }
    }
  }
  &:hover {
    .educationExperience-card-actions {
      @apply block;
    }
  }
}
