.userTechnologies-root {
  @apply cursor-pointer;
  .user-technologies-item {
    @apply mr-2;
  }
  .user-technologies-see-more {
    @apply text-sm font-bold;
  }
}

.user-technologies-modal {
  .user-technologies-modal-level {
    @apply mb-6;
    .user-technologies-modal-level-label {
      @apply block mb-2 text-sm text-gray-600;
    }
  }
}
