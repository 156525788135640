.timeTracker-message {
  @apply mt-10 mx-10 text-center font-semibold;
  &-text {
    @apply text-2xl;
  }
  &-actions {
    @apply mt-6 flex flex-col items-center;
  }
  &-button {
    @apply mb-4;
  }
}

