.layout-root {
  background: #fff !important;
  @apply flex h-full max-h-full;

  &-site {
    background: #fff !important;
  }
  &-title {
    @apply text-xl w-2/3 mb-0 pl-8 pr-5;
  }
}
