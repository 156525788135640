h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-semibold;
}

p {
  @apply text-base leading-6;
}
