.pageNotFound {
  &-root {
    max-width: 733px;
    padding: 0 10%;
    @apply w-full flex flex-col items-center justify-start text-center m-auto;
  }
  &-image {
    @apply w-full order-1;
  }
  &-title.ant-typography {
    margin-top: 0 !important;
    @apply text-xl order-3;
  }
  &-text {
    @apply text-gray-400 text-sm order-4;

    &-reaction {
      @apply text-gray-900 font-semibold order-2;
    }
  }
  &-actions {
    @apply flex border-red-50 mt-5 order-5;
  }
  &-button {
    @apply bg-sky-400 text-white hover:text-white hover:bg-blue-400 focus:outline focus:outline-gray-200 focus:outline-2 focus:border-white;

    &-back {
      @apply border border-gray-300 bg-white text-gray-900 mr-3;
    }
  }
}
