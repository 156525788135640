.welcome-root {
  .welcome-header {
    @apply relative w-full;
    .welcome-image {
      @apply pb-1 h-40;
    }
    button {
      @apply absolute right-0;
    }
  }
  h2 {
    @apply mb-4;
  }
}
