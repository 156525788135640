.editProfile-root {
  .editProfile-row {
    @apply flex mb-5;
    &-item:not(:last-child) {
      @apply mr-6;
    }
  }
  .editProfile-email {
    @apply mb-1;
  }
  .editProfile-form-grid {
    @apply grid grid-cols-2 gap-x-5 gap-y-7;
  }
}
