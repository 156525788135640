.button-root {
  @apply inline-flex items-center font-semibold;
  transition: background-color 0.3s;
  &.ant-btn > .anticon {
    @apply flex;
  }
  &.primary {
    @apply bg-sky-400 text-white h-auto;
    &:disabled {
      @apply opacity-30 cursor-not-allowed;
    }
    &:hover {
      @apply text-white bg-blue-400;
    }
    &:focus {
      @apply border border-solid border-gray-200;
    }
    &.danger {
      @apply bg-rose-500;
    }
  }
  &.text {
    @apply text-sky-400;
  }
  &.default {
    @apply border-gray-300;
    &:disabled {
      @apply opacity-30 text-gray-900 bg-white;
    }
  }
  &.text {
    @apply h-min p-0 text-sky-400;
    &:disabled {
      @apply opacity-30 cursor-not-allowed;
    }
  }
}
