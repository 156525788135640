.tableCustom-root {
  .ant-table-content {
    @apply rounded-md;
  }
  .ant-table-thead {
    @apply h-11;
    .ant-table-cell {
      @apply py-0 bg-gray-200 text-xs;
      &.ant-table-column-sort {
        @apply bg-gray-200;
      }
      .ant-table-column-sorters {
        @apply justify-start;
        .ant-table-column-title {
          @apply flex items-center;
        }
      }
    }
  }
  .ant-table-tbody {
    tr {
      @apply h-16;
    }
    tr.ant-table-row:nth-of-type(even) {
      &,
      &:hover,
      .ant-table-cell-row-hover,
      .ant-table-column-sort {
        @apply bg-gray-200;
      }
    }
  }
}
