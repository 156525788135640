.profileInHeader-root {
  @apply flex flex-col;

  &-person {
    @apply flex items-start mt-5;
  }

  &-avatar {
    @apply mr-3;
  }

  &-list {
    @apply w-full flex flex-col;

    &-item {
      width: 100% !important;
      margin-inline: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      pointer-events: visible;

      &-anchor {
        padding: 0 0 0 8px !important;
        @apply w-full h-full flex items-center;

        &:hover,
        &:focus {
          background-color: rgb(245 245 245) !important;
        }
      }

      &-icon.anticon {
        font-size: 18px !important;
      }
    }
  }

  &-avatar.avatar-root {
    border: 3px solid rgb(17 39 54) !important;
    @apply flex rounded-full;

    .ant-avatar-image {
      @apply border-0;
    }
  }
}
