.detailsReview-root {
  .detailsReview-header {
    @apply flex justify-between items-center;
    .detailsReview-title {
      &,
      & span {
        @apply flex items-center text-xl font-semibold;
      }
      & :first-child {
        @apply mr-1;
      }
    }
  }
}
