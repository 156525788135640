.userInfoSection-root {
  .user-links-container {
    @apply flex flex-col;
    .user-single-link {
      @apply flex flex-col pb-4;
      .social-media-label {
        @apply pb-1 text-gray-400;
      }
      .user-link {
        @apply text-sky-400 font-semibold;
        .anticon {
          @apply text-sky-400 font-semibold;
        }
      }
    }
  }
  .user-personal-info-section {
    .user-personal-info-subsection {
      @apply pb-8;
      .user-bio-label {
        @apply text-base font-semibold;
      }
      .inner-subheading {
        @apply pb-3;
      }
      .user-info {
        &,
        & p {
          @apply text-sm text-gray-500;
        }
      }
    }
  }
}
