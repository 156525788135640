.todaySection-root {
  .todaySection-header {
    @apply flex justify-between items-center pt-5;
    .todaySection-title {
      @apply text-lg;
    }
  }
  .todaySection-projects {
    @apply pt-10;
  }
  .todaySection-total-time {
    @apply flex justify-between mt-6 p-4 rounded-lg bg-gray-100 text-sm;
    &-warning {
      @apply ml-4 text-gray-400 font-semibold;
    }
  }
}

.timeBreakdown-root {
  @apply mt-8 p-0;
  .timeBreakdown-header {
    @apply flex justify-between items-center relative;
  }
}

.doughnutChartsSection-root {
  @apply flex mt-8;
  .doughnutChart-root {
    @apply w-1/2;
  }
  & > :first-child {
    @apply mr-8;
  }
  .doughnutChart-header {
    @apply flex justify-between items-start;
    .doughnutChart-title {
      @apply mb-6 text-base;
    }
    .doughnutChart-select {
      .ant-select-selector {
        @apply h-4;
      }
      .ant-select-selection-item {
        @apply leading-none text-gray-400;
      }
    }
  }

  .doughnutChart-data {
    @apply flex items-center;
    .doughnutChart-chart {
      @apply w-1/2;
    }
    .doughnutChart-legend {
      @apply w-1/2 ml-14;
      .doughnutChart-item {
        @apply flex items-center text-xs;
        &-percentage {
          @apply p-2 w-max h-max text-white;
        }
        &-data {
          @apply ml-1.5;
        }
        &-main {
          @apply font-semibold;
        }
        &-main,
        &-sub {
          @apply mb-0 text-gray-400;
        }
      }
    }
  }
}
