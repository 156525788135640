.textarea-root {
  .rich-text-editor {
    @apply rounded;
    .ql-toolbar {
      @apply flex items-center justify-start h-9 bg-gray-200 rounded-t-lg;

      .ql-formats {
        @apply m-0;
      }
    }
    .ql-container {
      @apply h-32 rounded-b-lg;
    }
    .ql-editor {
      p {
        @apply text-sm;
      }
      &.ql-blank::before {
        @apply font-sans text-sm not-italic text-gray-400;
      }
    }
  }
  .textarea-count {
    @apply mt-3 text-xs text-gray-400;
    &.invalid {
      @apply text-rose-500;
    }
  }
}
