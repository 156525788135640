.tokensPage-root {
  .currentBalanceSection-root {
    .currentBalanceSection-header {
      @apply flex justify-between items-center my-2;
      .currentBalanceSection-title {
        @apply mb-0 text-lg;
      }
    }
    .currentBalanceSection-cards {
      @apply flex my-12;
      .currentBalanceSection-card {
        @apply flex-grow;
        &:first-child {
          @apply mr-8;
        }
        .ant-card-meta-detail {
          .ant-card-meta-title {
            @apply mb-0;
          }
        }
        &-content {
          @apply mt-4 text-3xl;
        }
      }
    }
  }
  .transactionsHistorySection-table {
    .transaction-container {
      @apply flex justify-between items-center px-1;
      .transaction-initiator,
      .transaction-receiver {
        @apply flex items-center w-5/12;
        .transaction-avatar {
          @apply mr-3;
        }
        p {
          @apply mb-0 text-sm;
        }
        &-name,
        .transaction-amount {
          @apply font-semibold;
        }
        .transaction-receiver-job-role {
          @apply text-gray-400;
        }
      }
    }
    .transaction-date {
      @apply text-gray-400 text-sm;
    }
  }
}
