.timeRangePicker-root {
  .timeRangePicker-icon {
    @apply text-base font-semibold;
  }
  .timeRangePicker-range {
    @apply text-lg font-semibold mr-4 ml-1.5 cursor-pointer;
  }
  .timeRangePicker-input {
    @apply inline w-0 invisible absolute left-6 top-2;
    .ant-picker {
      @apply w-0;
    }
  }
  .timeRangePicker-radio-group {
    @apply inline-flex items-center w-min h-11 ml-2 p-1 bg-gray-200 rounded-lg; //TODO: remove ml-2 when date picker is removed
  }
  .timeRangePicker-radio-button {
    @apply inline-flex items-center h-8 border-none rounded-lg bg-gray-200 text-gray-900 text-sm transition-colors duration-300;
    &.ant-radio-button-wrapper {
      @apply h-9;
    }
    &.ant-radio-button-wrapper-checked {
      @apply bg-white text-gray-900;
    }
    &:not(.ant-radio-button-wrapper-disabled)::before {
      @apply bg-transparent;
    }
  }
}
