.layoutHeader-root {
  padding-inline: 0 !important;
  background: #fff !important;
  border-bottom: 1px solid rgb(229 229 229);
  @apply flex items-center top-0 z-10 sticky;

  .trigger {
    top: 50%;
    margin-left: -12px;
    transform: translateY(-50%);
    @apply absolute flex justify-center rounded-full w-6 h-6 bg-white shadow z-20;
  }
}
