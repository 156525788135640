.timeSummary-data {
  @apply flex items-center;
  div {
    @apply mr-14;
  }
  &-user {
    @apply flex justify-start items-center;
    & &-avatar {
      @apply mr-3;
    }
    &-name {
      @apply block font-semibold;
    }
    &-job {
      @apply block text-gray-400;
    }
  }
  &-name {
    @apply block font-semibold mb-2;
  }
  &-total {
    @apply mr-20;
    &-hours {
      @apply block;
    }
  }
  .non-billable {
    @apply text-red-300;
  }
}
.timeSummary-projects-list {
  @apply pt-5;
}
