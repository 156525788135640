.tagCustom-root {
  @apply border-none mb-2 p-3 bg-gray-100 font-semibold text-xs text-gray-500;
  line-height: 18px;
  &.tagCustom-closable {
    @apply pr-0 py-0;
    .anticon-close {
      @apply pl-1 pr-3 py-3 text-sm;
    }
  }
}
