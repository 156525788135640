.singleRequestReviewDetails-root {
  @apply max-h-full overflow-y-scroll;

  &-single-review {
    @apply flex flex-col justify-start;

    & + & {
      border-top: 1px solid rgb(208 213 221);
      @apply pt-10 mt-10;
    }

    &_type-status {
      @apply flex justify-between;
    }

    &_status {
      @apply flex justify-center items-center w-5 h-5 rounded-full;

      &-pending {
        @apply bg-gray-400;
      }
      &-approved {
        @apply bg-emerald-600;
      }
      &-rejected {
        @apply bg-rose-500;
      }
      &-icon {
        color: #fff !important;
      }
    }

    &_summary {
      @apply flex;
    }

    &_requester,
    &_reviewer {
      @apply w-1/2;
    }

    &_requester {
      @apply pr-10 mr-10 mt-3;

      &-info {
        border: 1px solid rgb(208, 213, 221);
        @apply flex items-center px-3 py-2 rounded-lg;

        &-name {
          @apply ml-3;
        }
      }
      &-education {
        @apply my-3;
      }
      &-tags {
        @apply py-3;

        &_icon {
          color: rgb(158 160 164) !important;
          @apply mx-3;
        }
      }
    }

    &_reviewer {
      @apply relative;
      &:before {
        content: '';
        width: 1px;
        margin-left: -40px;
        @apply block bg-gray-300 h-full absolute;
      }
    }

    &_actions {
      @apply flex justify-end;
      button + button {
        @apply ml-3;
      }
    }
  }
}
