.paginationCustom-container {
  .ant-card-body {
    @apply py-3;
  }
}

.paginationCustom-root {
  @apply flex text-gray-900;
  .ant-pagination-total-text {
    @apply text-gray-500 font-semibold justify-self-start flex-grow;
  }
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    @apply text-gray-900;
  }
  .ant-pagination-item.ant-pagination-item-active {
    @apply bg-gray-900 border-none;
    a {
      @apply text-white;
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    button {
      @apply text-gray-400;
    }
  }
}
