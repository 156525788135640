.dividedSection-root {
  @apply flex justify-between py-2;
  .dividedSection-heading {
    @apply w-1/3;
    .dividedSection-subtitle {
      @apply text-sm text-gray-400 font-normal;
    }
    .dividedSection-button {
      @apply px-0;
      .anticon {
        @apply text-sky-400 text-2xl;
      }
      span:last-child {
        @apply ml-1;
      }
    }
  }
  .dividedSection-content {
    @apply w-2/3;
  }
}
