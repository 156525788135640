.tabulation-root {
  .ant-tabs .ant-tabs-tab-btn .ant-badge {
    @apply text-gray-400 font-semibold;
  }
  .ant-tabs-tab {
    @apply text-gray-400 font-semibold;
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-badge,
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    @apply text-gray-900;
  }
  .ant-tabs .ant-tabs-ink-bar {
    background: rgb(17, 39, 54);
  }
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    @apply border-b border-neutral-200;
  }
}
