.headingWithBackButton-root {
  @apply flex items-center;
  &,
  & span,
  & h2 {
    @apply flex items-center text-xl font-semibold;
  }
  h2 {
    @apply mb-0;
  }
  & :first-child {
    @apply mr-1;
  }
}
