.checkboxCustom-root {
  @apply flex;
  .ant-checkbox {
    .ant-checkbox-inner {
      @apply w-5 h-5 relative;
      &::after {
        @apply absolute top-2 h-3 w-1.5;
        inset-inline-start: 19.5%;
      }
      &.ant-checkbox-checked {
        @apply bg-sky-400;
      }
    }
  }
}
