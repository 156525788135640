.socialMediaBox-root {
  @apply flex justify-between items-center;
  .socialMediaBox-header {
    @apply flex items-center;
    img {
      @apply mr-6;
    }
  }
  .socialMediaBox-name,
  .socialMediaBox-url {
    @apply text-sm mb-0;
  }
  .socialMediaBox-name {
    @apply font-semibold;
  }
  .socialMediaBox-url {
    @apply text-gray-400;
  }
}
