.userPicker-root {
  @apply w-2/5;
  .ant-select-selection-item {
    @apply flex items-center;
    .userPicker-option-email {
      @apply hidden;
    }
  }
}
.userPicker-option {
  @apply flex items-center py-2 cursor-pointer hover:bg-gray-100;
  &-data {
    @apply ml-3;
    .userPicker-option-name {
      @apply mb-0 text-sm font-semibold text-gray-900;
    }
    .userPicker-option-email {
      @apply mb-0 text-sm text-gray-400;
    }
  }
}
