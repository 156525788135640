.modal-root {
  .ant-modal-content {
    @apply p-6;
    .scrollable {
      @apply max-h-96 h-full overflow-y-auto;
    }
  }
  .modal-form-grid {
    @apply grid grid-cols-2 gap-x-6;
  }
  .small-modal-content {
    @apply text-center py-1;
  }

  .modal-heading {
    @apply mb-4 text-xl leading-6;
  }

  .modal-subheading {
    @apply text-gray-500 text-sm font-normal;
  }
}
