.selectCustom-root {
  .select-flags {
    @apply text-gray-900;
    button {
      height: 46px;
      @apply border-gray-300 rounded-lg pr-4 text-base;
      span {
        @apply text-sm font-sans;
      }
      &::after {
        @apply border-t-gray-400 border-4 border-b-0;
      }
      &[aria-expanded='true'] {
        @apply border-blue-400;
        box-shadow: 0 0 4px 0 #2c73a4, 0 1px 10px 0 rgba(57, 139, 195, 0.05);

        &::after {
          @apply border-b-gray-400 border-4 border-t-0;
        }
      }
    }
    ul {
      @apply rounded-lg mt-1 border-none;
      box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
        0 9px 28px 8px rgb(0 0 0 / 5%);

      input {
        @apply h-11 border border-solid border-gray-300 rounded-lg p-3 text-sm;

        &:focus {
          @apply border-blue-400;
        }
      }

      li {
        @apply px-4 py-2;
      }
    }
  }

  .ant-select-borderless {
    .ant-select-selector {
      @apply items-center;
    }
  }

  .ant-select-selector {
    @apply h-12;
  }
  .ant-select .ant-select-selection-placeholder {
    @apply text-gray-900;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    @apply h-12;
    span {
      line-height: 46px;
    }
  }

  .ant-select .ant-select-arrow .anticon:not(.ant-select-suffix) {
    @apply pointer-events-none;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    @apply text-gray-900;
  }
}

.ant-select-single.ant-select-lg .ant-select-selector {
  @apply text-sm;
}
