.login-page {
  @apply relative lg:flex-row flex-col w-full;

  &-logo {
    max-width: 147px;
    @apply lg:absolute lg:top-4 lg:left-4 static mt-4 ml-4 mb-5;
  }

  &-left-side {
    @apply flex relative lg:w-1/3 h-1/2 lg:h-full pb-10 lg:pb-5 w-full justify-center items-center;
  }

  &-right-side {
    background-image: url('../../assets/images/login-background.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @apply flex lg:w-2/3 h-1/2 lg:h-full w-full px-14 py-16 text-white items-end;

    &-content {
      @apply w-full max-w-3xl h-max;
    }
  }

  &-form {
    max-width: 300px;
    @apply flex flex-col justify-start;

    span,
    &-link {
      @apply text-sm;
    }

    &-link {
      &:hover {
        color: rgb(56, 140, 196) !important;
        text-decoration: underline !important;
      }
    }

    &-button {
      color: rgb(17, 39, 54) !important;
      border: 1px solid rgb(229 229 229);
      height: 44px;
      padding: 0.625rem !important;
      border-radius: 0.5rem;
      svg {
        @apply text-2xl mr-2;
      }
    }
  }
}
