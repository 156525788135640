.projectTimeCard-root {
  @apply flex items-center;
  .projectTimeCard-avatar {
    img {
      @apply object-contain;
    }
  }
  &.projectTimeCard-total {
    @apply justify-between;
    .projectTimeCard-description {
      @apply my-0 text-sm;
    }
    .projectTimeCard-time {
      @apply text-right;
      .projectTimeCard-time-amount {
        @apply flex justify-end font-semibold mb-1 text-right;
      }
      .projectTimeCard-time-billable-status {
        @apply mb-0 text-sm capitalize;
        &.billable {
          @apply text-gray-600;
        }
        &.non-billable {
          @apply text-red-300;
        }
      }
    }
  }
  .projectTimeCard-info {
    @apply flex;
  }
  .projectTimeCard-text {
    @apply ml-2 mr-4 flex-1;
  }
  .projectTimeCard-title {
    @apply mb-1 text-sm;
  }
  .projectTimeCard-subheading {
    @apply mb-0.5 text-gray-400 text-sm;
  }
  &.projectTimeCard-percentage {
    .projectTimeCard-info {
      @apply w-1/3;
    }
    .projectTimeCard-percentage-container {
      @apply w-full;
      .projectTimeCard-percentage-data {
        @apply flex justify-between items-center text-gray-400 text-sm;
      }
      .projectTimeCard-percentage-bar {
        @apply grow inline-block h-2 mr-3 rounded-md bg-gray-300;
      }
    }
  }
}
