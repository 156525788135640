.projectCard-root {
  @apply flex flex-col justify-between border border-gray-400 rounded-md bg-white;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  .projectCard-top {
    @apply p-5;
    .projectCard-header {
      @apply flex items-center;
      .projectCard-name-and-client {
        @apply flex flex-col ml-2;
        .projectCard-name {
          @apply mb-0;
        }
        .projectCard-client {
          @apply text-gray-400;
        }
      }
    }
    .projectCard-summary {
      @apply mt-4;
      .projectCard-see-more {
        @apply text-sky-600;
      }
    }
    .projectCard-dates {
      @apply text-gray-400 mt-4;
    }
  }
  .projectCard-middle {
    @apply flex flex-row mt-5;
  }
  .projectCard-bottom {
    @apply flex flex-col p-5 mt-4;
    .projectCard-collaborators-title {
      @apply text-gray-600 font-semibold uppercase text-xs;
    }
    .projectCard-collaborators {
      @apply flex flex-row justify-between pt-2;
      .projectCard-collaborators-container {
        .avatar-root,
        .ant-avatar-circle {
          @apply border border-white rounded-full;
          &:nth-child(2) {
            @apply right-2 z-10;
          }
          &:nth-child(3) {
            @apply right-4 z-20;
          }
          &:nth-child(4) {
            @apply right-6 z-30;
          }
        }
      }
    }
  }
}
