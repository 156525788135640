.avatar-root {
  @apply relative inline-block;

  &-xl {
    .ant-avatar-string {
      @apply text-5xl;
    }
  }

  .avatar-icon {
    padding: 0.1rem;
    @apply absolute text-base rounded-full;

    &-xs {
      right: -10%;

      &-bottom {
        bottom: -5%;
      }

      &-top {
        top: -5%;
      }
    }

    &-sm,
    &-lg {
      @apply right-0;
      &-bottom {
        @apply bottom-0;
      }
      &-top {
        @apply top-0;
      }
    }
    &-md {
      @apply -right-1;
      &-bottom {
        @apply -bottom-1;
      }
      &-top {
        @apply -top-1;
      }
    }

    &-xl {
      right: 5%;
      @apply border border-gray-300 border-solid p-0;

      &-bottom {
        @apply bottom-0;
      }

      &-top {
        top: 5%;
      }
    }
  }
}
