.profileHeader-root {
  .profileHeader {
    &-cover-photo-container {
      @apply w-full;
      
      img {
        @apply w-full;
      }
    }
    &-profile-picture {
      border-width: 5px;
      @apply -mt-6 mr-6 rounded-full border-solid border-white;

      .change-profile-picture-icon {
        @apply flex items-center justify-center w-11 h-11 rounded-full p-1 bg-white cursor-pointer;
        
        svg {
          @apply mt-1 text-xl;
        }
      }
    }
    &-user {
      @apply flex items-center justify-between px-8;

      &-name {
        @apply font-sans mb-1 text-3xl leading-10;
      }
      &-info {
        @apply flex items-center;
      }
      &-additional-info {
        @apply text-gray-400 text-sm;
      }
      &-actions {
        @apply flex;
      }
    }
  }
  .ant-avatar-image {
    @apply border-0;
  }
}
