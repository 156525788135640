.welcome-social-media-header {
  @apply flex justify-between items-center mb-6;
  .welcome-social-media-image {
    @apply h-32;
  }
}

.welcome-social-media-list {
  @apply pl-0;
}
